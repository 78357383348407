// Dependencies

// Components
import NavBarWrapper from '../nav-bar-wrapper/NavBarWrapper';
import Footer from '../footer/Footer';

// Styling
import styles from './Page.module.scss';

const Page = ({ children }) => {
	return (
		<div id={styles.page} className="page">
			<NavBarWrapper />

			<div id={styles.content} className="container">
				<div className={styles.withSidePadding + ' withSidePadding'}>
					{children}
				</div>
			</div>
			<Footer />
		</div>
	);
};
export default Page;
